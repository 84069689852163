.input-row {
    display: flex;
}

.half-a-row {
    width: 50%;
    margin: 8px;
}

.full-row {
    width: 100%;
    margin: 8px;
}

.input-box {
    width: 100%;
    padding: 10px;
    border: 1.5px solid #707070;
    border-radius: 4px;
    box-sizing: border-box;
}

.check-box-bottom-padding {
    padding-bottom: 8px;
}

.check-box-right-margin {
    margin-right: 12px;
}

.line-up-checkbox {
    display: flex;
}

.checkbox-sizing {
    height: 20px;
    width: 20px;
}

.other-input-box {
    border: 1px solid #3345554D;
}

.submit-button {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.submit-margin-spacing {
    margin-top: 40px;
    margin-bottom: 40px;
}

.error-border {
    border: #EC2D6D solid 1.5px;
    box-shadow: 0px 0px 8px #8E1B4164;
}

.error-text {
    color: #EC2D6D;
}

.margin-spacing {
    margin-bottom: 30px;
}

.spinner-spacing {
    padding-left: 20px;
}

.recaptcha-margin-spacing{
    margin-top: 40px;
}

@media only screen and (max-width: 640px) {
    .input-row {
        flex-direction: column;
    }

    .input-row .half-a-row {
        width:100%;
    }
}