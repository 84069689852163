.item-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.item-row .item {
    margin: 10px;
    max-width: 150px;
}

.item-row .item img {
    max-width: 100%;
    max-height: 90px;
}