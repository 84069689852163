.title-bottom-margin {
    margin-bottom: 5px;
}

.feature-line-height{
    line-height: 150%;
}

.windows-icon-padding{
    padding-left: 10px;
}