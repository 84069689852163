#footer-menu {
    background-color: #5381bd;
}

#footer-menu-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.footer-menu-list {
    margin: 0 25px;
}

.footer-menu-list h3 {
    color: #FFFFFF;
    font-size: 13px;
    margin-bottom: 2em;
}

.footer-menu-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-menu-list ul li {
    display: block;
    margin: 5px 0;
}

.footer-menu-list ul li a {
    color: #FFFFFF;
    font-size: 13px;
}

#footer-copyright {
    background-color: #0F324F;
}

#footer-copyright-text {
    color: #FFFFFF;
    text-align: center;
    font-size: 13px;
}

#logistics-logo {
    margin: 10px 0 20px;
    width: 220px;
}

#copyright-text {
    color: #BEBEBE;
    font-size: 11px;
    line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
    #footer-menu-container {
        flex-direction: column;
    }

    .footer-menu-list {
        margin-bottom: 25px;
    }

    .footer-menu-list h3 {
        margin-bottom: 0.75em;
    }
}
