.gallery-image-width {
    width: 100%;
}

.list-item {
    display: flex;
    width: 28%
}

@media only screen and (max-width: 640px) {
    .list-item {
        width:40%;
    }
}

    