.left {
    display: flex;
    width: 100%;
    align-content: flex-start;
    justify-content: start;
}

.right {
    display: flex;
    width: 100%;
    align-content: end;
    justify-content: end;
}

.image-height-170 {
    height: 170px;
}

.image-height-220 {
    width: 425px;
}

.image-left-padding {
    padding-left: 70px;
}

.image-right-padding {
    padding-right: 70px;
}

.text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-fit{
    height: 100%;
}

.content-fit{
    align-items: center
}

@media only screen and (max-width: 1024px) {
    .image-height-220 {
        width: 300px;
    }

    .image-right-padding {
        padding-right: 30px;
    }


}

@media only screen and (max-width: 768px) {
    .wrap.content-fit {
        flex-direction: column;
    }

    .wrap.content-fit.reverse {
        flex-direction: column-reverse;
    }

    .text-wrap {
        margin-bottom: 30px;
    }

    .image-left-padding, .image-right-padding {
        padding-left: 0;
        padding-right: 0;
    }
}