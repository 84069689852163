.toast-message {
    padding: 16px;
    color: #FFFFFF;
    width: 354px;
    box-shadow: 0px 0px 8px #004F4766;
    border-radius: 5px;
}

.success {
    background-color: #00C6B2;
}

.error {
    background-color: #EC2D6D;
}