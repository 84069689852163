.link {
  text-decoration: none;
  color: #3A76B3;
}

#header {
  height: 60px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

#header-logo {
  height: 40px;
  margin-left: 50px
}

#header-navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

#header .btn {
  font-size: 14px;
  padding: 5px 10px;
}

#header #btn-login {
    margin-right: 50px;
    line-height: 1em;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #c31530;
    border-radius: 5px;
    border: 2px solid #c31530;
    background-color: transparent;
    font-weight: bold;
    transition: background-color 0.5s ease-out;
}

#header #btn-login:hover {
    background-color: #c31530;
    color: #FFFFFF;
}

#header #btn-login span {
    display: inline-block;
    margin-top: 4px;
    padding-bottom: 0;
    margin-right: 5px;
}

#header-navigation {
  margin: 0;
  padding: 0;
}

#header-navigation li {
  display: inline-block;
  margin: 0 25px;
  font-weight: 600;
  font-size: 17px;
}

#header-navigation li:hover a {
  color: #1C5A8C;
}

.hide-image {
  visibility: hidden
}

#mobile-navigation-btn {
    height: 30px;
    width: 30px;
    position: relative;
    cursor: pointer;
    z-index: 9999;
    margin-left: 25px;
    display: none;
    padding: 0;
    border: 0;
    background: none;
}

#mobile-navigation {
    height: 5px;
    border-radius: 5px;
    background-color: #3A76B3;
    position: absolute;
    top:15px;
}

#mobile-navigation:before {
    content: '';
    height: 5px;
    width: 30px;
    border-radius: 5px;
    background-color: #3A76B3;
    display: block;
    cursor: pointer;
    margin-top: -10px;
}

#mobile-navigation:after {
    content: '';
    height: 5px;
    width: 30px;
    border-radius: 5px;
    background-color: #3A76B3;
    display: block;
    cursor: pointer;
    margin-top: 15px;
}

#mobile-navigation.open {
    background-color: #FFFFFF;
}

#mobile-navigation.open:before {
    transform: rotate(45deg);
    margin-top:0;
    position: absolute;
    top:0;
    left:0;
}

#mobile-navigation.open:after {
    transform: rotate(-45deg);
    margin-top: 0;
    position: absolute;
    top:0;
    left:0;
}

#mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 70%;
    bottom: 0;
    background-color: #ffffff;
    box-shadow: 0px 0 10px #bbb;
}

#mobile-menu-navigation {
    margin-top:70px;
    padding-left:25px;
}

#mobile-menu-navigation ul {
    padding-left:0;
    margin-left:0;
}

#mobile-menu-navigation li {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom:1em;
}

#mobile-menu-navigation li a {
    font-size:20px;
}

#header #btn-login span.mobile-login-text {
    display: none;
}

@media only screen and (max-width: 1023px) {
    #header-navigation {
            display: none;
        }

        #header #btn-login {
            margin-right: 25px;
        }

        #header-logo {
            margin-left: 0;
        }

        #mobile-navigation-btn {
            display: block;
        }
    }

    @media only screen and (min-width: 1024px) {
        #mobile-menu {
            display: none;
        }
    }

@media only screen and (max-width: 800px) {
    #mobile-menu {
        right: 50%;
    }

    #header #btn-login span.mobile-login-text {
        display: inline-block;
    }

    #header #btn-login span.desktop-login-text {
        display: none;
    }

    #header-logo {
        height: 30px;
    }
}