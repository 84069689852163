.panel-light {
    background-color: #eaf0fa !important;
}

#hero-animation {
    height: 500px;
    background-color: white;
    position: relative;
    overflow: hidden;
}

#asset-vision-logo {
    position: absolute;
    width: 240px;
    top: 70px;
    left: 50%;
    margin-left: -120px;
    z-index:9;
}

#hero-animation .animation-asset {
    position: absolute;
    bottom: 10px;
}

#hero-animation #asset-sun {
    position: absolute;
    top: 4%;
    right: 0;
    max-width: 90%;
    padding-right: 6%;
}

#hero-animation #asset-hgv {
    height: 190px;
    left: 50%;
    margin-left: -390px
}

#hero-animation #asset-hgv .asset-wheel {
    height:48px;
}

#hero-animation #asset-hgv .asset-wheel-1 {
    left: 105px;
}

#hero-animation #asset-hgv .asset-wheel-2 {
    left: 161px;
}

#hero-animation #asset-hgv .asset-wheel-3 {
    left: 217px;
}

#hero-animation #asset-hgv .asset-wheel-4 {
    left: 470px;
}

#hero-animation #asset-hgv .asset-wheel-5 {
    left: 533px;
}

#hero-animation #asset-hgv .asset-wheel-6 {
    left: 682px;
}

#hero-animation #asset-psv {
    height: 155px;
    left:0;
    transform:translateX(-510px);
}

#hero-animation #asset-psv .asset-wheel {
    height:42px;
}

#hero-animation #asset-psv .asset-wheel-1 {
    left: 108px;
}

#hero-animation #asset-psv .asset-wheel-2 {
    left: 368px;
}

#hero-animation #asset-van {
    height: 122px;
    transform: translateX(-300px);
}

#hero-animation #asset-van .asset-wheel-small {
    height: 34px;
}

#hero-animation #asset-van .asset-wheel-1 {
    left: 42px;
}

#hero-animation #asset-van .asset-wheel-2 {
    left: 219px;
}

#hero-animation #asset-tree-1 {
    height: 260px;
    transform: translateX(300px);
    right:0%;
}

#hero-animation #asset-tree-2 {
    height: 220px;
    transform: translateX(250px);
    right: 0%;
}

#hero-animation #asset-bush-1 {
    height: 50px;
    transform: translateX(300px);
    right: 0%;
    bottom: 2px;
}

#hero-animation #asset-bush-2 {
    height: 50px;
    transform: translateX(300px);
    right: 0%;
    bottom: 2px;
}

#hero-animation .asset-wheel,
#hero-animation .asset-wheel-small {
    position: absolute;
    bottom: 0;
    left: 0;
}

#hero-animation #hero-animation-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background-position: center bottom;
    background-repeat: repeat-x;
}

.content.hero-content h2 {
    color: #2576B8;
    text-align: left;
    line-height: 1.3em;
    font-weight: 500;
    font-size: 32px;
    width: 1000px;
    margin-left: -70px;
}

.content.hero-content p {
    font-family: 'Overpass', sans-serif;
    font-size: 16px;
    line-height: 1.7em;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #eaf0fa;
}

@media only screen and (max-width: 880px) {

    #hero-animation {
        height:380px;
    }

    #hero-animation #asset-tree-1 {
        height: 130px;
    }

    #hero-animation #asset-tree-2 {
        height: 110px;
    }

    #hero-animation #asset-bush-1 {
        height: 25px;
    }

    #hero-animation #asset-bush-2 {
        height: 25px;
    }

    #hero-animation #hero-animation-bg {
        height:12px;
    }

    .asset.wheel {
        height:24px !important;
    }

    #hero-animation #asset-hgv {
        height: 95px;
        margin-left:-195px;
    }

    #hero-animation #asset-hgv .asset-wheel {
        height: 24px;
    }

    #hero-animation #asset-hgv .asset-wheel-1 {
        left: 53px;
    }

    #hero-animation #asset-hgv .asset-wheel-2 {
        left: 81px;
    }

    #hero-animation #asset-hgv .asset-wheel-3 {
        left: 109px;
    }

    #hero-animation #asset-hgv .asset-wheel-4 {
        left: 235px;
    }

    #hero-animation #asset-hgv .asset-wheel-5 {
        left: 267px;
    }

    #hero-animation #asset-hgv .asset-wheel-6 {
        left: 341px;
    }

    #hero-animation #asset-psv {
        height: 77px;
    }

    #hero-animation #asset-psv .asset-wheel-1 {
        left: 54px;
    }

    #hero-animation #asset-psv .asset-wheel-2 {
        left: 183px;
    }

    #hero-animation #asset-psv .asset-wheel {
        height: 21px;
    }

    #hero-animation #asset-van {
        height: 61px;
    }

    #hero-animation #asset-van .asset-wheel-1 {
        left: 21px;
    }

    #hero-animation #asset-van .asset-wheel-2 {
        left: 110px;
    }

    #hero-animation #asset-van .asset-wheel-small {
        height: 17px;
    }

    #hero-animation .animation-asset img {
        max-height: 100%;
    }

    #hero-animation .animation-asset {
        bottom: 6px;
    }
}

@media only screen and (max-width: 400px) {
    #hero-animation #asset-hgv {
        margin-left: -235px
    }
}