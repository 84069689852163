body {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    color: #2F4557;
    font-weight: normal;
    font-style: normal;
    padding-top: 60px;
    margin: 0;
}

a {
  text-decoration: none;
  color: #6699CC;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

:focus {
  outline: none;
  outline-offset: unset;
}

p {
  margin-top: 0;
}

.header-shadow {
  box-shadow: 0px 0 10px #bbb;
}

.content {
  width: 900px;
  margin: 0 auto;
  padding: 50px 0;
  max-width:100%;
}

.content-centered {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.content-right {
  display: flex;
  width: 100%;
  align-content: end;
  justify-content: end;
}

.content h2 {
  font-size: 42px;
  font-weight: 500;
}

.content h3 {
  font-size: 17px;
  font-weight: 600;
}

.content p {
  font-size: 14px;
  line-height: 1.7em;
}

.column-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.column {
  flex: 1;
  margin: 0 20px 0 0;
}

.column-content {
  margin-bottom: 30px;
}

.column-content p {
  line-height: 1.7em;
}

.content.hero-content h2 {
    color: #5381bd;
    text-align: center;
    line-height: 1.3em;
    font-size: 32px;
    width: 1000px;
    margin-left: -70px;
    font-weight: 500;
}

.blue {
    color: #5381bd;
}

.dark-blue {
  color: #008CFF;
}

.light-blue {
  color: #6699CC;
}

.open {
    font-family: "Overpass", sans-serif !important;
}

.museo {
    font-family: "Overpass", sans-serif !important;
}

.fs42 {
  font-size: 42px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fw400, .fw300 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.bottom-margin-5 {
  margin-bottom: 5px !important;
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

.center-text {
  text-align: center !important;
}

.bold-text {
  font-weight: bold;
}


.dark-text-colour {
  color: #2F4557;
}

.underline-link {
    text-decoration: underline;
    word-break: break-word;
}

.padding-left-90 {
  padding-left: 90px;
}

.padding-right-90 {
  padding-right: 90px;
}

.wrap {
  display: flex;
}

.capitalize {
  text-transform: uppercase;
}

.btn {
    border: 2px solid;
    border-radius: 5px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    transition: background-color 0.5s ease-out;
    line-height: 1em;
}

.btn:hover {
    background-color: #c31530;
    color: #FFFFFF;
}

.blue-btn {
    background-color: transparent;
    color: #c31530;
    border-color: #c31530;
}

.light-blue-btn {
    background-color: transparent;
    color: #c31530;
    border-color: #c31530;
}

.dark-blue-btn {
  background-color: #3A76B3;
  color: #FFFFFF;
  border-color: #3A76B3;
}

.dark-blue-btn:hover {
  background-color: #1C5A8C;
  border-color: #1C5A8C;
}

.cursor-pointer {
  cursor: pointer;
}

.body-line-height {
  line-height: 1.7em;
}

@media only screen and (max-width: 1024px) {
    .content.hero-content h2 {
        max-width:100%;
        padding-left:40px;
        padding-right:40px;
        margin-left:0;
    }

    .basic-panel .content {
        padding: 50px;
    }

    .basic-panel .content .basic-panel .content {
        padding: 0;
    }

    .item-row {
        justify-content: space-evenly;
    }

    .padding-right-90 {
        padding-right:0;
    }

    .padding-left-90 {
        padding-left: 0;
    }

    .column-container {
        flex-direction: column;
    }
    .column-content {
        margin-bottom:0;
    }

    .column-content .fs28 {
        font-size:22px !important;
    }
}

@media only screen and (max-width: 400px) {
    .content.hero-content h2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .basic-panel .content {
        padding:25px;
    }

    .content h2 {
        line-height: 1.2em;
    }

    .fs32 {
        font-size:26px !important;
    }
}

