.drop-down-header {
    width: 100%;
    background: none;
    border: 0;
    font-family: museo-sans,sans-serif;
    text-align: left;
    font-weight: 300;
    font-size: 28px;
    color: #2f4557;
    line-height: 1.3em;
    justify-content: space-between;
}

.arrow-button {
    border: 0;
    background-color: #FFFFFF;
    height: 30px;
}

.title-spacing {
    flex-basis: 90%
}

.button-spacing {
    display: flex;
    justify-content: end;
    flex-basis: 10%
}

.down-spacing {
    padding-left: 30px;
    padding-right: 30px;
}

.even-height {
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .down-spacing {
        padding:0;
    }
}
