.spacing {
    padding-bottom: 180px;
}

.position-image {
    position: absolute;
    top: 547px
}

.banner-image {
    height: 150px;
}

.banner-image-position {
    position: absolute;
    height: 220px;
    left: 0;
    right: 0;
    overflow-x: hidden;
}

.image-support-scene {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    margin-left: -780px;
}

@media only screen and (max-width: 1000px) {

    .banner-image-position {
        height: 110px;
    }

    .image-support-scene {
        margin-left: -392px;
    }

    .banner-image {
        height:50px;
        margin-top:50px;
    }
}

@media only screen and (max-width: 580px) {
    .banner-image {
        display:none;
    }
}