body {
    background-color: #eaeff9;
}

.center-layout {
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeff9;
}

.underline {
    text-decoration: underline;
}

.top-margin {
    margin-top: 10px;
}