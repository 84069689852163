.benefits-section {
    display: flex;
    flex-wrap: wrap;
}

.benefits-column {
    display: flex;
    width: 50%;
}

.benefits-segment {
    display: block;
    padding: 5px
}

.left-padding15 {
    padding-left: 15px;
}

.right-padding15 {
    padding-right: 15px;
}

@media only screen and (max-width: 768px) {
    .benefits-column {
        width: 100%;
    }

    .benefits-segment.left-padding15 {
        padding-left: 0px;
    }
}