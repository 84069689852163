.icon-box {
    width: 180px;
    height: 180px;
    background: #ccd9ed;
    margin: 10px;
}

.icon-box p {
    color: #225d93;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    margin:0;
    padding:0;
}

.icon-box .title img {
    max-width: 60%;
    max-height: 60%;
}

.icon-box .title,
.icon-box .hover-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding:10px;
}

.icon-box .hover-content {
    justify-content: space-between;
    padding: 30px 10px;
    display: none;
}

.icon-box:hover .hover-content {
    display: flex;
    flex-direction: column;
}

.icon-box:hover .title {
    display: none;
}

.break {
    flex-basis: 100%;
}

.middle {
    margin-top: 30px;
}